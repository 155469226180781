export const pageMountedCode = () => {
    if (typeof(window) != 'undefined') {
        let timeoutInterval = 10;
        if (window.location.href.includes('localhost')) {
            timeoutInterval = 1000;
        }
        setTimeout(() => {
            setPageMountedState(true);
            hideWaitPage();
        }, timeoutInterval)
    }
}